export enum VisualSize {
  Normal = "normal",
  Maximized = "maximized",
}

export enum MstrMessageType {
  Pendo = "pendo",
  AccountingSummaryToggle = "accountSummaryToggle",
}

export enum DossierEventType {
  GraphicSelected = 1,
  OnLayoutChanged,
}

export enum LogType {
  Information = 1,
  Error = 2,
}
